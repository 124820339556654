<template>
  <div class="about">
    <h1>This is an about page</h1>
    <section class="section">
      <div class="container">
        <h1 class="title">Hello World</h1>
        <p class="subtitle has-text-link">
          My first website with <strong>Bulma</strong>!
        </p>
      </div>
    </section>
  </div>
</template>
